import { useGameStore } from '/@/kernel-mn/stores/game';
import { useSportsStore } from '/@/kernel-mn/stores/sports';
import { useHot } from '/@/kernel-mn/stores/useHot';
import { showConfirmDialog } from 'vant';
import router from '/@/router';
import { isApp } from '/@/kernel-mn/utils';
import { mb_err, mb_loading, mb_RNEvent, mb_t, mb_toast } from '/@/plugins/global';
import config from '/@/kernel-mn/config';
import helperFGBY from '/@/kernel-mn/helpers/helperFGBY';
import i18n, { $t2en } from '/@/languages';
import { useTenantStore } from '/@/kernel-mn/stores/tenant';
import { storeToRefs } from 'pinia';
import TenantRight from '/@/kernel-mn/config/TenantRight';
import { useAppStore } from '/@/kernel-mn/stores/app';
import { useUserStore } from '/@/kernel-mn/stores/user';
import { useHaxiStore } from '/@/kernel-mn/stores/haxi';
import game3Config from '/@/kernel-mn/stores/otherGames/game3Config';
import { transferThirdGameUrl, G_goLogin } from '/@/hooks/common';
import { mb_getLogoById } from "/@/hooks/publicData.ts";
import { setJSON } from '../kernel-mn/utils/storage';

// 哈希游戏收藏
export const _handleHaXiFavorite = (data, cb) => {
  const haxiStore = useHaxiStore();
  const { hotData } = useHot();
  const { isLogined } = hotData;

  if (!isLogined) {
    return mb_toast(mb_t('亲，麻烦请登录哦'));
  }
  let { play_collection: playCollection } = data;
  const bool = playCollection === 1;
  haxiStore.haxiCollection({ data }).then((res: any) => {
    if (res.response_code && res.response_code === '9999') {
      const text = bool ? mb_t('收藏成功') : mb_t('取消收藏成功');
      mb_toast(text);
      if (cb && typeof cb === 'function') cb(res);
    } else {
      const text = bool ? mb_t('收藏失败') : mb_t('取消收藏失败');
      mb_toast(text);
    }
  })
    .catch(() => {
      const text = bool ? mb_t('收藏失败') : mb_t('取消收藏失败');
      mb_toast(text);
    });
};

// 收藏
export const _handleFavorite = (item, cb, type) => {
  const gameStore = useGameStore();
  const { hotData } = useHot();
  const { isLogined } = hotData;

  if (!isLogined) {
    return mb_toast(mb_t('亲，麻烦请登录哦'));
  }
  let { play_collection: playCollection } = item;
  const bool = playCollection === 1;
  gameStore
    .setPlayCollection({
      data: {
        ...item,
        type
      }
    })
    .then((res: any) => {
      if (res.response_code && res.response_code === '9999') {
        const text = bool ? mb_t('收藏成功') : mb_t('取消收藏成功');
        mb_toast(text);
        if (cb && typeof cb === 'function') cb(res);
      } else {
        const text = bool ? mb_t('收藏失败') : mb_t('取消收藏失败');
        mb_toast(text);
      }
    })
    .catch(() => {
      const text = bool ? mb_t('收藏失败') : mb_t('取消收藏失败');
      mb_toast(text);
    });
};

// 体育 跳转第三方游戏
export const goTYThirdPlatform = item => {
  const gameStore = useGameStore();
  const { hotData } = useHot();
  const { isLogined, isFreeUser, userInfo } = hotData;
  let isCommit = false;

  if (!isLogined.value) {
    G_goLogin();
    mb_toast(mb_t('亲，请先登录哦'));
    return;
  }
  if (isCommit) {
    mb_toast(mb_t('操作过于频繁'));
    return;
  }
  if (isFreeUser.value) {
    mb_toast(mb_t('试玩账号不能进入该游戏、请注册正式会员'));
    router.replace('/');
    return;
  }
  if (!item) {
    mb_toast(mb_t('彩种信息缺失'));
    return;
  }
  // 进入滚球页面
  if (Number(item.play_id) === 931) {
    if (userInfo.value.fict_flag !== 1) {
      router.push('/game?key=gunqiu');
    } else {
      mb_toast(mb_t('用户权限不足'));
    }
    return;
  }
  let playstate = gameStore.playAllStates[item.play_id];
  if (playstate && playstate.play) {
    if (playstate.play.play_state === 2) {
      mb_toast(mb_t('暂停销售'));
      return;
    } else if (playstate.play.play_state === 3) {
      mb_toast(mb_t('停止销售'));
      return;
    }
  }
  isCommit = true;
  const isapp = isApp();
  const loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
  gameStore
    .checking3Game({
      play_id: item.play_id
    })
    .then((res: any) => {
      isCommit = false;
      if (res.response_code && res.response_code === '9999') {
        const payload: any = {
          play_id: item.play_id
        };
        if (item.play_name === 'BTI' || item.play_name === 'SB') {
          payload.data = { type: '1' };
        }
        if (item.play_id === 981) {
          // IM电竞
          payload.data = { type: '1' };
        }
        if (item.play_id === 1313) {
          // PRETTY真人
          payload.data = { type: '1' };
        }
        if ([1384, 1389].includes(payload.play_id)) {
          payload.data = { isMobileOpen: 1 }
        }
        if (payload.play_id === 1388) {
          payload.data = { type: '200' }
        }
        gameStore
          .get3GameUrl(payload)
          .then((res: any) => {
            loading.close();
            if (res && res.url) {
              const _urlInfo = transferThirdGameUrl(res.url, payload);
              showConfirmDialog({
                message: '<div><div>' + mb_t('进入游戏') + '</div></div>',
                className: 'enter3gameDialog',
                overlayClass: 'enter3gameDialogoverlay',
                showConfirmButton: true,
                showCancelButton: true,
                allowHtml: true,
                closeOnPopstate: true,
                closeOnClickOverlay: false,
                confirmButtonText: mb_t('确定'),
                cancelButtonText: mb_t('取消')
              })
                .then(() => {
                  if (isapp) {
                    mb_RNEvent.open3Game(_urlInfo.url, item);
                  } else {
                    goLinkGame(_urlInfo);
                  }
                })
                .catch(() => {
                  close3Game(payload.play_id);
                });
            } else {
              mb_toast(mb_t('未获取到三方平台地址'));
            }
          })
          .catch(err => {
            loading.close();
            mb_err(err.toString());
          });
      } else {
        loading.close();
      }
    })
    .catch(err => {
      loading.close();
      isCommit = false;
      if (err) {
        if (err.exMessage) {
          mb_err(err.exMessage.toString());
        } else if (err.info) {
          mb_err(err.info.toString());
        }
      }
    });
};

// 真人 跳转第三方游戏
export const goZRThirdPlatform = async (item) => {
  const gameStore = useGameStore();
  const sportsStore = useSportsStore()
  const { hotData } = useHot();
  const { isLogined, isFreeUser } = hotData;
  let isCommit = false;

  if (!isLogined.value) {
    G_goLogin();
    mb_toast(mb_t('亲，请先登录哦'));
    return;
  }
  if (isCommit) {
    mb_toast(mb_t('操作过于频繁'));
    return;
  }
  if (isFreeUser.value) {
    mb_toast(mb_t('试玩账号不能进入该游戏、请注册正式会员'));
    router.replace('/');
    return;
  }
  if (!item) {
    mb_toast(mb_t('彩种信息缺失'));
    return;
  }
  let playstate = gameStore.playAllStates[item.play_id];
  if (playstate && playstate.play) {
    if (playstate.play.play_state === 2) {
      mb_toast(mb_t('暂停销售'));
      return;
    } else if (playstate.play.play_state === 3) {
      mb_toast(mb_t('停止销售'));
      return;
    }
  }
  const limited = await gameStore.checking3GameLimited({ play_id: item.play_id })
  const blance = sportsStore.blance / 1000
  // @ts-ignore
  if (blance < limited?.deposit_min_money) {
    // @ts-ignore
    mb_toast(mb_t('余额低于{num}，暂时无法游戏', { num: limited?.deposit_min_money }));
    return false
  }
  isCommit = true;
  item.gameId = item.play_id;
  const isapp = isApp();
  const loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
  gameStore
    .checking3Game({
      play_id: item.play_id
    })
    .then((res: any) => {
      isCommit = false;
      if (res.response_code && res.response_code === '9999') {
        const payload: any = {
          play_id: item.play_id,
          data: { type: item.play_name }
        };
        if (Number(item.play_id) === 412) {
          payload.data = { type: 'SMG_titaniumLiveGames_MP_Baccarat' };
        }
        if (Number(item.play_id) === 1313) {
          // PRETTY真人
          payload.data = { type: '1' };
        }
        if ([1384, 1389].includes(payload.play_id)) {
          payload.data = { isMobileOpen: 1 }
        }
        if (payload.play_id === 1388) {
          payload.data = { type: '200' }
        }
        gameStore
          .get3GameUrl(payload)
          .then((res: any) => {
            loading.close();
            if (res && res.url) {
              const _urlInfo = transferThirdGameUrl(res.url, payload);
              showConfirmDialog({
                message: '<div><div>' + mb_t('进入游戏') + '</div></div>',
                className: 'enter3gameDialog',
                overlayClass: 'enter3gameDialogoverlay',
                showConfirmButton: true,
                showCancelButton: true,
                allowHtml: true,
                closeOnPopstate: true,
                closeOnClickOverlay: false,
                confirmButtonText: mb_t('确定'),
                cancelButtonText: mb_t('取消')
              })
                .then(() => {
                  if (isapp) {
                    mb_RNEvent.open3Game(_urlInfo.url, item);
                  } else {
                    goLinkGame(_urlInfo);
                  }
                })
                .catch(() => {
                  close3Game(payload.play_id);
                });
            } else {
              mb_toast(mb_t('未获取到三方平台地址'));
            }
          })
          .catch(err => {
            loading.close();
            mb_err(err.toString());
          });
      } else {
        loading.close();
      }
    })
    .catch(err => {
      loading.close();
      isCommit = false;
      if (err) {
        if (err.exMessage) {
          mb_err(err.exMessage.toString());
        } else if (err.info) {
          mb_err(err.info.toString());
        }
      }
    });
};

// 捕鱼 跳转第三方游戏
export const goBYThirdPlatform = item => {
  // play_id 需要用当前点击有的的subplayId，传入进来的play_id是父级的id，这里转换一下
  item.play_id = item.play_id ? item.play_id : item.subplayid

  const gameStore = useGameStore();
  const { hotData } = useHot();
  const { isLogined, isFreeUser } = hotData;
  let isCommit = false;

  if (!isLogined.value) {
    G_goLogin();
    mb_toast(mb_t('亲，请先登录哦'));
    return;
  }
  // 1 正常销售2 暂停销售3 停止销售4 内部测试5 归档，前端只处理1、2、4
  if (item.state === 2) {
    mb_toast(mb_t('该游戏暂停销售'));
    return;
  }
  if (item.state === 4) {
    mb_toast(mb_t('该游戏为内部测试'));
    return;
  }
  if (isCommit) {
    mb_toast(mb_t('操作过于频繁'));
    return;
  }
  if (isFreeUser.value) {
    mb_toast(mb_t('试玩账号不能进入该游戏、请注册正式会员'));
    router.replace('/');
    return;
  }
  if (!item) {
    mb_toast(mb_t('彩种信息缺失'));
    return;
  }
  let playstate = gameStore.playAllStates[item.play_id];
  if (playstate && playstate.play) {
    if (playstate.play.play_state === 2) {
      mb_toast(mb_t('暂停销售'));
      return;
    } else if (playstate.play.play_state === 3) {
      mb_toast(mb_t('停止销售'));
      return;
    }
  }
  isCommit = true;
  item.gameId = item.play_id;
  const isapp = isApp();
  const loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
  gameStore
    .checking3Game({
      play_id: item.play_id
    })
    .then((res: any) => {
      isCommit = false;
      if (res.response_code && res.response_code === '9999') {
        let play = config.getPlayInfo(item.subplayid);
        let payload: any = {};
        // 将有差异的拿出来判断，其他不需要
        payload.play_id = item.play_id;
        if (item.play_name === 'MGBY' || item.play_name === 'THBY' || item.play_name === 'CQ9BY' || item.play_name === 'BSBY') {
          payload.play_id = play.main_id;
          payload.data = { type: play.play_name };
        } else if (item.play_name === 'JDBBY') {
          payload.play_id = play.main_id;
          payload.data = {
            gtype: 7,
            type: play.play_name
          };
        } else if (item.play_name === 'FGBY') {
          payload.play_id = play.main_id;
          payload.data = { type: helperFGBY.getGameId(item.play_id) };
        }
        if (payload.play_id === 1313) {
          // PRETTY真人
          if (payload.data) {
            payload.data.type = '1';
          } else {
            payload.data = { type: '1' };
          }
        }
        if ([1384, 1389].includes(payload.play_id)) {
          payload.data = { isMobileOpen: 1 }
        }
        if (payload.play_id === 1388) {
          payload.data = { type: '200' }
        }
        payload.buyu_game_id = `${item.play_id}`
        gameStore
          .get3GameUrl(payload)
          .then((res: any) => {
            loading.close();
            if (res && res.url) {
              const _urlInfo = transferThirdGameUrl(res.url, payload);
              showConfirmDialog({
                message: '<div><div>' + mb_t('进入游戏') + '</div></div>',
                showConfirmButton: true,
                className: 'enter3gameDialog',
                overlayClass: 'enter3gameDialogoverlay',
                showCancelButton: true,
                allowHtml: true,
                closeOnPopstate: true,
                closeOnClickOverlay: false,
                confirmButtonText: mb_t('确定'),
                cancelButtonText: mb_t('取消')
              })
                .then(() => {
                  if (isapp) {
                    mb_RNEvent.open3Game(_urlInfo.url, item);
                  } else {
                    goLinkGame(_urlInfo);
                  }
                })
                .catch(() => {
                  close3Game(payload.play_id);
                });
            } else {
              mb_toast(mb_t('未获取到三方平台地址'));
            }
          })
          .catch(err => {
            loading.close();
            if (err && err.info) {
              mb_err(err.info.toString());
            }
          });
      }
    })
    .catch(err => {
      loading.close();
      isCommit = false;
      if (err) {
        if (err.exMessage) {
          mb_err(err.exMessage.toString());
        } else if (err.info) {
          mb_err(err.info.toString());
        }
      }
    });
};

// 电子 跳转第三方游戏
export const goDZThirdPlatform = item => {
  const gameStore = useGameStore();
  const { hotData } = useHot();
  const { isLogined, isFreeUser } = hotData;
  let isCommit = false;

  if (!isLogined) {
    G_goLogin();
    mb_toast(mb_t('亲，请先登录哦'));
    return;
  }
  if (isFreeUser.value) {
    mb_toast(mb_t('试玩账号不能进入该游戏、请注册正式会员'));
    router.replace('/');
    return;
  }
  // 1 正常销售2 暂停销售3 停止销售4 内部测试5 归档，前端只处理1、2、4
  if (item.gameState && +item.gameState === 2) {
    mb_toast(mb_t('该游戏暂停销售'));
    return;
  }
  if (item.state === 4) {
    mb_toast(mb_t('该游戏为内部测试'));
    return;
  }
  if (isCommit) {
    mb_toast(mb_t('操作过于频繁'));
    return;
  }
  isCommit = true;
  const isapp = isApp();
  const loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
  gameStore
    .checking3Game({
      play_id: item.play_id || item.playId
    })
    .then((res: any) => {
      loading.close();
      isCommit = false;
      if (res.response_code && res.response_code === '9999') {
        jumpDZ3Game(item, isapp);
      }
    })
    .catch(err => {
      loading.close();
      isCommit = false;
      if (err) {
        if (err.exMessage) {
          mb_err(err.exMessage.toString());
        } else if (err.info) {
          mb_err(err.info.toString());
        }
      }
    });
};

// 棋牌 跳转第三方游戏
export const goQPThirdPlatform = item => {
  const gameStore = useGameStore();
  const { hotData } = useHot();
  const { isLogined, isFreeUser } = hotData;
  if (item.gameState && +item.gameState === 3) {
    mb_toast(mb_t('该游戏已暂停销售，请移至其它游戏'));
    return;
  }
  if (item.isLotteryTicket) {
    if (!isLogined) {
      G_goLogin();
      mb_toast(mb_t('亲，请先登录哦'));
      return;
    }
    goLotteryPlatform(item);
  } else {
    let play = config.getPlayInfo(item.play_id);
    if (play && (play.cat === 'QP' || play.cat === 'DZ' || play.cat === 'HB')) {
      if (play.cat === 'QP') {
        if ((item.playId + '').length === 3) {
          let qiPaiPlay = [];
          if (gameStore.qiPaiPlayGames.length) {
            gameStore.qiPaiPlayGames.forEach(item => {
              if (+item.state !== 3) {
                qiPaiPlay.push(item);
              }
            });
          }
          const qiPaiPlayNew = qiPaiPlay ? qiPaiPlay : getQiPaiPlay(JSON.parse(window.localStorage.getItem('QiPaiPlayGames')));
          if (qiPaiPlayNew.some(ite => +ite.play_id === item.play_id)) {
            qiPaiPlayNew.forEach((item, index) => {
              if (+item.play_id === item.play_id) {
                gameStore.setSelectedType(index + 1);
              }
            });
          } else {
            mb_toast(mb_t('游戏未开启,尽请期待'));
          }
        } else {
          let index = gameStore.gameTabsData.findIndex(value => value.playId === item.playId);
          gameStore.setSelectedType(index);
        }
      } else if (play.cat === 'DZ') {
        mb_toast(mb_t('功能待开发'));
      } else if (play.cat === 'HB') {
        mb_toast(mb_t('功能待开发'));
      }
    } else if (play && (play.cat === 'AG' || play.cat === 'TY')) {
      if (!isLogined.value) {
        G_goLogin();
        mb_toast(mb_t('亲，请先登录哦'));
        return;
      }
      if (isFreeUser.value) {
        mb_toast(mb_t('试玩账号不能进入该游戏、请注册正式会员'));
        router.replace('/');
        return;
      }
      const isapp = isApp();
      const loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
      gameStore
        .checking3Game({
          play_id: item.play_id || item.playId
        })
        .then((res: any) => {
          loading.close();
          if (res.response_code && res.response_code === '9999') {
            videoSportsJump(item, item.gameId, isapp);
          }
        })
        .catch(err => {
          loading.close();
          if (err) {
            if (err.exMessage) {
              mb_err(err.exMessage.toString());
            } else if (err.info) {
              mb_err(err.info.toString());
            }
          }
        });
    } else {
      if (!isLogined) {
        G_goLogin();
        mb_toast(mb_t('亲，请先登录哦'));
        return;
      }
      if (isFreeUser.value) {
        mb_toast(mb_t('试玩账号不能进入该游戏、请注册正式会员'));
        router.replace('/');
        return;
      }
      let data: any = { type: item.gameKey };
      if (Number(item.gameId) === 340 || Number(item.gameId) === 397) {
        data.gtype = item.gtype;
      }
      if (Number(item.gameId) === 1326) {
        data.type = 1;
      }
      const isapp = isApp();
      const loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
      gameStore
        .checking3Game({
          play_id: item.play_id || item.playId
        })
        .then((res: any) => {
          if (res.response_code && res.response_code === '9999') {
            const payload = {
              data,
              play_id: item.gameId
            };
            if (payload.play_id === 1313) {
              // PRETTY真人
              payload.data.type = '1';
            }
            if ([1384, 1389].includes(payload.play_id)) {
              payload.data.isMobileOpen = 1
            }
            if (payload.play_id === 1388) {
              payload.data.type = '200';
            }
            gameStore
              .get3GameUrl(payload)
              .then((res: any) => {
                loading.close();
                const _urlInfo = transferThirdGameUrl(res.url, payload);
                if (res && res.url) {
                  showConfirmDialog({
                    message: '<div><div>' + mb_t('进入游戏') + '</div></div>',
                    showConfirmButton: true,
                    showCancelButton: true,
                    className: 'enter3gameDialog',
                    overlayClass: 'enter3gameDialogoverlay',
                    allowHtml: true,
                    closeOnPopstate: true,
                    closeOnClickOverlay: false,
                    confirmButtonText: mb_t('确定'),
                    cancelButtonText: mb_t('取消')
                  })
                    .then(() => {
                      if (isapp) {
                        mb_RNEvent.open3Game(_urlInfo.url, item);
                      } else {
                        goLinkGame(_urlInfo);
                      }
                    })
                    .catch(() => {
                      close3Game(payload.play_id);
                    });
                } else {
                  mb_toast(mb_t('未获取到三方平台地址'));
                }
              })
              .catch(err => {
                loading.close();
                mb_err(err.toString());
              });
          } else {
            loading.close();
          }
        })
        .catch(err => {
          loading.close();
          if (err) {
            if (err.exMessage) {
              mb_err(err.exMessage.toString());
            } else if (err.info) {
              mb_err(err.info.toString());
            }
          }
        });
    }
  }
};

// urlstr 多的url参数
export const goLotteryPlatform = (obj, urlstr = '', needLogin = true) => {
  let value = config.deepCopy(obj);
  const { hotData } = useHot();
  const { userInfo, isLogined } = hotData;
  if (!isLogined.value && needLogin) {
    G_goLogin();
    mb_toast(mb_t('亲，请先登录哦'));
    return;
  }
  // 2 暂停销售
  if ([2].includes(+obj.play_state || +obj.state)) {
    mb_toast(mb_t('暂停销售'), 2);
    return false;
  }
  const play = config.getPlayInfo(value.play_id);
  if (!value.sub_play_id && play) {
    value.sub_play_id = play.default;
    if (value.play_cat === 4 && play._default) {
      value.sub_play_id = play._default;
    }
  }
  if (userInfo.value.fict_flag) {
    if (value.type === 3 || value.type === 4 || value.type === 5 || value.type === 8) {
      let url = `/pk10?playid=${value.play_id}&goback=0&subplayid=${value.sub_play_id}`;
      urlstr ? url += `&${urlstr}` : '';
      router.push(url);
    } else if (value.type === 6) {
      let url;
      if (value.play_id === 33) {
        url = '/hklottery?playid=' + value.play_id + '&goback=0&subplayid=' + value.sub_play_id;
        urlstr ? url += `&${urlstr}` : '';
        router.push(url);
      } else if (value.play_id === 42) {
        url = '/cqlhc?playid=' + value.play_id + '&goback=0&subplayid=' + value.sub_play_id;
        urlstr ? url += `&${urlstr}` : '';
        router.push(url);
      }
    } else if (value.type === 7) {
      let url = '/k3?playid=' + value.play_id + '&goback=0&subplayid=' + value.sub_play_id;
      urlstr ? url += `&${urlstr}` : '';
      router.push(url);
    } else if (value.type === 0) {
      let play = config.getPlayInfo(value.play_id) || {};
      if (play.cat === 'QKL') {
        let url = '/qkl?playid=' + value.play_id;
        urlstr ? url += `&${urlstr}` : '';
        router.push(url);
      } else {
        let url = '/ydc?playid=' + value.play_id;
        urlstr ? url += `&${urlstr}` : '';
        router.push(url);
      }
    } else if (value.type === 19) {
      let url = '/fc3d?playid=' + value.play_id + '&goback=0&subplayid=' + value.sub_play_id;
      urlstr ? url += `&${urlstr}` : '';
      router.push(url);
    } else if (value.type === 20) {
      let url = '/xnb?playid=' + value.play_id;
      urlstr ? url += `&${urlstr}` : '';
      router.push(url);
    } else if (value.type === 21) {
      let url = '/qkl?playid=' + value.play_id
      urlstr ? url += `&${urlstr}` : '';
      router.push(url);
    }
  } else {
    if (value.type === 3 || value.type === 4 || value.type === 5 || value.type === 8) {
      let url = '/pk10?playid=' + value.play_id + '&goback=0&subplayid=' + value.sub_play_id;
      urlstr ? url += `&${urlstr}` : '';
      router.push(url);
    } else if (value.type === 6) {
      let url;
      if (value.play_id !== 42) {
        url = '/hklottery?playid=' + value.play_id + '&goback=0&subplayid=' + value.sub_play_id;
        urlstr ? url += `&${urlstr}` : '';
        router.push(url);
      } else if (value.play_id === 42) {
        url = '/cqlhc?playid=' + value.play_id + '&goback=0&subplayid=' + value.sub_play_id;
        urlstr ? url += `&${urlstr}` : '';
        router.push(url);
      }
    } else if (value.type === 7) {
      if (value.play_cat === 4) {
        let url = '/pk10?playid=' + value.play_id + '&goback=0&subplayid=' + value.sub_play_id;
        urlstr ? url += `&${urlstr}` : '';
        router.push(url);
      } else {
        let url = '/k3?playid=' + value.play_id + '&goback=0&subplayid=' + value.sub_play_id;
        urlstr ? url += `&${urlstr}` : '';
        router.push(url);
      }
    } else if (value.type === 0) {
      let play = config.getPlayInfo(value.play_id) || {};
      if (play.cat === 'QKL') {
        let url = '/qkl?playid=' + value.play_id;
        urlstr ? url += `&${urlstr}` : '';
        router.push(url);
      } else {
        let url = '/ydc?playid=' + value.play_id;
        urlstr ? url += `&${urlstr}` : '';
        router.push(url);
      }
    } else if (value.type === 19) {
      let url = '/fc3d?playid=' + value.play_id + '&goback=0&subplayid=' + value.sub_play_id;
      urlstr ? url += `&${urlstr}` : '';
      router.push(url);
    } else if (value.type === 20) {
      let url = '/xnb?playid=' + value.play_id;
      urlstr ? url += `&${urlstr}` : '';
      router.push(url);
    } else if (value.type === 21) {
      let url = '/qkl?playid=' + value.play_id;
      urlstr ? url += `&${urlstr}` : '';
      router.push(url);
    }
  }
};

export const goTianChengPlatform = info => {
  const gameStore = useGameStore();
  const { hotData } = useHot();
  const { isLogined, isFreeUser } = hotData;
  if (!isLogined.value) {
    G_goLogin();
    return;
  }
  if (isFreeUser.value) {
    showConfirmDialog({
      title: mb_t('提示'),
      message: mb_t('试玩账号不能进入该游戏、请注册正式会员'),
      confirmButtonText: mb_t('去注册'),
      cancelButtonText: mb_t('下次吧')
    })
      .then(() => {
        router.replace('/');
      })
      .catch(() => {
        router.back();
      });
    return;
  }
  const loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
  gameStore
    .checking3Game({
      play_id: info.playId || info.play_id
    })
    .then((res: any) => {
      loading.close();
      if (res.response_code && res.response_code === '9999') {
        jump3Game(info);
      }
    })
    .catch(err => {
      loading.close();
      if (err.exMessage) {
        mb_err(err.exMessage.toString());
      } else if (err.info) {
        mb_err(err.info.toString());
      }
    });
};

export const goBlockGame = item => {
  const { hotData } = useHot();
  const { isLogined } = hotData;
  if (!isLogined.value) {
    G_goLogin();
    mb_toast(mb_t('亲，请先登录哦'));
    return;
  }
  // Crash 直接跳
  if (item.name === 'crash') {
    router.push('/crash');
    return;
  }
  if (item.name === 'mine') {
    router.push('/mines');
    return;
  }
  if (item.name === 'dabble') {
    router.push('/double');
    return;
  }
  if (item.name === 'dice') {
    router.push('/dice');
    return;
  }
  const appStore = useAppStore();
  const userStore = useUserStore();
  if (!userStore.userInfo?.hash_wallet_address) {
    router.push('/hashWallet');
    return;
  }
  appStore.setData({ key: 'blockGameDetail', value: item });
  router.push('/blockGameDetail');
};

// 通多id判断 大类 如棋牌 电子 isbig 为真直接返回bigClass
export const determineType = (id, isbig = false) => {
  let bigClass = '';
  switch (isbig ? id : config.getGameCategory(Number(id))) {
    case 1:
      bigClass = 'qipai';
      break;
    case 2:
      bigClass = 'dianzi';
      break;
    case 3:
      bigClass = 'buyu';
      break;
    case 4:
    case 8:
      bigClass = 'caipai';
      break;
    case 5:
      bigClass = 'tiyu';
      break;
    case 6:
      bigClass = 'zhenren';
      break;
    case 7:
      bigClass = 'hongbao';
      break;
  }
  return bigClass;
};

// 根据cat_id判断大游戏分类数据
export const getGameInfo = item => {
  let gameInfo = null;
  switch (Number(item.big_type)) {
    case 1:
      gameInfo = {
        type: 'qipai',
        fav_type: 17
      };
      break;
    // case 4:
    //   gameInfo = {
    //     type: 'caipiao',
    //     fav_type: 16
    //   };
    //   break;
    case 5:
      gameInfo = {
        type: 'tiyu',
        fav_type: 15
      };
      break;
    case 4:
      gameInfo = {
        type: 'zhenren',
        fav_type: 11
      };
      break;
    // case 7:
    //   gameInfo = {
    //     type: 'hongbao',
    //     fav_type: 14
    //   };
    //   break;
    default:
      // 捕鱼
      if (item.main_id) {
        gameInfo = {
          type: 'buyu',
          fav_type: 13
        };
      } else if (item.upper_play_id) {
        gameInfo = {
          type: 'dianzi',
          fav_type: 12
        };
      } else {
        gameInfo = {
          type: 'caipiao',
          fav_type: 16
        };
      }
  }
  return gameInfo;
};

// 返回热门游戏封装后的数据
export const getHotGamesData = hotGames => {
  let arr = [];
  const glGgame = game3Config.gameArr.glg;
  const glDZGgame = game3Config.gameArr.dz;
  const qipai = game3Config.gameArr.qipai;
  // 真人图标
  const videoArr = game3Config.gameArr.video;
  // 体育图标
  const tiyuArr = game3Config.gameArr.tiyu;
  let lang = $t2en(i18n.global.locale);
  let str = 'qipai';
  let imgUrl = '';
  const tenantState = useTenantStore();
  const { imgDomain } = storeToRefs(tenantState);
  for (let i = 0; i < hotGames.length; i++) {
    const item = hotGames[i];
    let lotteryTicket = false;
    if (determineType(item.upper_play_id) === 'caipai') {
      let play = config.getPlayInfo(item.play_id);
      if (play) {
        imgUrl =
          imgDomain.value + 'staticQ/caipiao/default/' + lang + '/icon_play_' + (play.icon !== undefined ? (item.play_cat === 4 && play.base ? play.base + '_XY' : play.icon) : play.id) + '.png';
      }
      lotteryTicket = true;
    } else if (determineType(item.upper_play_id) === 'buyu') {
      imgUrl = `${imgDomain.value}staticQ/buyu/${item.upper_play_id}/${lang}/${'game_' + item.play_id}.png`;
    } else if (determineType(item.upper_play_id) === 'zhenren') {
      imgUrl = `${imgDomain.value}staticQ/caipiao/default/${lang}/icon_play_${item.play_id}.png`;
    } else if (determineType(item.upper_play_id) === 'dianzi') {
      imgUrl = `${imgDomain.value}staticQ/dianzi/${item.upper_play_id}/${lang}/game_${item.upper_play_id === 340 ? item.play_name : item.play_id}.png`;
    } else {
      imgUrl = `${imgDomain.value}staticQ/${determineType(item.upper_play_id)}/${item.upper_play_id}/${'game_' + (item.upper_play_id === 340 ? item.play_name : item.play_id)}.png`;
      if (glGgame.some(it => it === Number(item.upper_play_id))) {
        if (glDZGgame.some(it1 => it1 === Number(item.upper_play_id))) {
          str = 'dianzi';
        }
        imgUrl = `${imgDomain.value}staticQ/${str}/${item.upper_play_id}/${lang}/${'game_' + (item.upper_play_id === 340 ? item.play_name : item.play_id)}.png`;
      }
    }
    if (item.upper_play_id === 504 || (item.upper_play_id === 506 && item.play_id !== 506) || Number(item.upper_play_id) === 745) {
      imgUrl = `${imgDomain.value}staticQ/${item.upper_play_id === 504 ? 'caipiao' : 'qipai'}/${item.upper_play_id}/${lang}/game_${item.play_id}.png`;
    }
    if (item.upper_play_id === 468) {
      imgUrl = `${imgDomain.value}staticQ/caipiao/468/game_${item.play_id}.png`;
    } else if (Number(item.upper_play_id) === 750) {
      imgUrl = `${imgDomain.value}staticQ/caipiao/default/${lang}/${'icon_play_' + item.play_id}.png`;
    }
    if (glDZGgame.some(it1 => it1 === Number(item.upper_play_id))) {
      imgUrl = `${imgDomain.value}staticQ/dianzi/${item.upper_play_id}/${lang}/${'game_' + (item.upper_play_id === 340 ? item.play_name : item.play_id)}.png`;
    }
    if (videoArr.includes(item.upper_play_id) || tiyuArr.includes(item.upper_play_id)) {
      imgUrl = imgDomain.value + 'staticQ/caipiao/default/' + lang + '/icon_play_' + item.play_id + '.png';
    }
    if (qipai.some(it1 => it1 === Number(item.upper_play_id))) {
      imgUrl = `${imgDomain.value}staticQ/qipai/${item.upper_play_id}/${lang}/${'game_' + (item.upper_play_id === 340 ? item.play_name : item.play_id)}.png`;
    }
    // 哈希游戏
    if (item.play) {
      item.type = 'hash';
      item.play_name = item.play.name;
      item.play_cname = item.play.showname;
      item.play_id = item.id;
      item.isOriginal = !item.addressIn;
      imgUrl = '';
    }
    // 原创
    if (item.showName) {
      item.type = 'hash';
      item.play_name = item.name;
      item.play_cname = item.showName;
      item.play_id = item.id;
      item.isOriginal = true;
      imgUrl = '';
    }
    if (!arr.some(ele => ele.play_id == item.play_id)) {
      arr.push({
        ...item,
        playId: item.play_id,
        gameId: item.upper_play_id,
        gameImgUrl: imgUrl,
        gameKey: item.play_name,
        gameName: item.play_cname,
        isLotteryTicket: lotteryTicket
      });
    }
  }
  return arr
    .filter(item => item.state !== 3 && item.play_id !== item.upper_play_id)
    .sort((a, b) => {
      const topDiff = b.is_top - a.is_top;
      const hotDiff = b.hot_val - a.hot_val;
      if (topDiff === 0) {
        // 一样的话再根据 hot_val 排列
        if (hotDiff === 0) {
          // 一样的话再根据 sub_play_id 排列 第三方彩sub_play_id为0，所以使用play_id
          return (b.sub_play_id || b.play_id) - (a.sub_play_id || a.play_id);
        } else {
          return hotDiff;
        }
      } else {
        return topDiff;
      }
    });
};

export const goThirdPlatform = (item, type) => {
  let gameKey = '';
  if (determineType(item.upper_play_id) === 'qipai') {
    gameKey = 'chess';
  } else if (determineType(item.upper_play_id) === 'dianzi') {
    gameKey = 'electronic';
  } else if (determineType(item.upper_play_id) === 'buyu') {
    gameKey = 'fishing';
  }
  // 游戏大类跳转
  if (item.upper_id === 0 && item.big_type === 1) {
    if (determineType(item.play_id) === 'qipai') {
      gameKey = 'chess';
    } else if (determineType(item.play_id) === 'dianzi') {
      gameKey = 'electronic';
    } else if (determineType(item.play_id) === 'buyu') {
      gameKey = 'fishing';
    }
    router.push('/game?key=' + gameKey + '&upper_play_id=' + item.play_id);
    return;
  }
  if (Number(item.upper_play_id) === Number(item.play_id) && gameKey) {
    router.push('/game?key=' + gameKey + '&upper_play_id=' + item.upper_play_id);
    return;
  }
  if (type === 'chess') {
    goQPThirdPlatform(item);
  } else if (type === 'electronic') {
    goDZThirdPlatform(item);
  } else if (type === 'fishing') {
    goBYThirdPlatform(item);
  } else if (type === 'sports') {
    goTYThirdPlatform(item);
  } else if (type === 'live') {
    goZRThirdPlatform(item);
  }
};

export const getFishData = hotFishing => {
  if (!hotFishing) {
    return []
  }
  let playList1 = [];
  const tenantState = useTenantStore();
  const { imgDomain } = storeToRefs(tenantState);
  if (hotFishing.length > 0) {
    for (let i = 0; i < hotFishing.length; i++) {
      let key = Object.keys(hotFishing[i])[0];
      let play = config.getPlayInfo(key);
      const { play_cat, play_collection } = hotFishing[i];
      if (!play) {
        continue;
      }
      let whichPlay = null;
      let isSale = true;
      let mainId = key;
      if (play.type === 13) {
        whichPlay = 'isAgBy';
        if (hotFishing[i][key] === 3 || !TenantRight.TR_APP_30002017001) {
          isSale = false;
        }
      } else if (play.type === 20) {
        whichPlay = 'isBGBY';
        if (hotFishing[i][key] === 3) {
          isSale = false;
        }
      } else if (play.type === 21) {
        whichPlay = 'isXYBY';
        if (hotFishing[i][key] === 3) {
          isSale = false;
        }
      } else if (play.type === 27) {
        mainId = play.main_id;
        whichPlay = 'isMGBY';
        if (handleState(play.main_id)) {
          isSale = false;
        } else if (hotFishing[i][key] === 3) {
          isSale = false;
        }
      } else if (play.type === 28) {
        mainId = play.main_id;
        whichPlay = 'isJDBBY';
        if (handleState(play.main_id)) {
          isSale = false;
        } else if (hotFishing[i][key] === 3) {
          isSale = false;
        }
      } else if (play.type === 29) {
        mainId = play.main_id;
        whichPlay = 'isTHBY';
        if (handleState(play.main_id)) {
          isSale = false;
        } else if (hotFishing[i][key] === 3) {
          isSale = false;
        }
      } else if (play.type === 31) {
        mainId = play.main_id;
        whichPlay = 'isCQ9BY';
        if (handleState(play.main_id)) {
          isSale = false;
        } else if (hotFishing[i][key] === 3) {
          isSale = false;
        }
      } else if (play.type === 32) {
        mainId = play.main_id;
        whichPlay = 'isBSBY';
        if (handleState(play.main_id)) {
          isSale = false;
        } else if (hotFishing[i][key] === 3) {
          isSale = false;
        }
      } else if (play.type === 33) {
        mainId = play.main_id;
        whichPlay = 'isFGBY';
        if (handleState(play.main_id)) {
          isSale = false;
        } else if (hotFishing[i][key] === 3) {
          isSale = false;
        }
      } else if (play.type === 34) {
        mainId = play.main_id;
        whichPlay = 'isDTBY';
        if (handleState(play.main_id)) {
          isSale = false;
        } else if (hotFishing[i][key] === 3) {
          isSale = false;
        }
      }
      const mainPlay = config.getPlayInfo(mainId) || {};
      if (isSale && !play.hide) {
        playList1.push({
          play_collection,
          play_cat,
          play_type: play.type,
          title: play.play_cname,
          subplayid: play.default,
          play_id: key,
          play_name: mainPlay?.play_name || '',
          whichPlay: whichPlay,
          main_id: mainId,
          state: hotFishing?.[i]?.[key] || '',
          gameImgUrl: `${imgDomain.value}staticQ/buyu/${mainId}/${$t2en(i18n.global.locale)}/game_${key}.png`
        });
      }
    }
  }
  return playList1;
};

const replace = val => {
  return val.replace('已停盘，开盘时间为', mb_t('已停盘，开盘时间为'));
};

// 检测彩票玩法状态，进入对应路由
export const testCanPlay = (value, item) => {
  // 跳相应的子彩种界面
  const gameStore = useGameStore();
  const { hotData } = useHot();
  const { userInfo } = hotData;
  let loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
  gameStore
    .testCanPlay({
      main_play_id: value.play_id || value.id
    })
    .then(() => {
      loading?.close();
      if (value.state !== 1 && value.state !== 4) {
        switch (value.state) {
          case 2:
            mb_toast(mb_t('该彩种暂停销售'));
            break;
          case 5:
            mb_toast(mb_t('该彩种不在销售时间'));
            break;
          case 9:
            mb_toast(mb_t('该彩种已归档'));
            break;
        }
      } else if (value.state === 1) {
        let url = '';
        if (value.cat === 'PC00') {
          if (item.is_room === 0) {
            router.push({ path: '/chatroomselect', query: { play_name: value.play_name } })
          } else {
            let url = '/pc28?playid=' + item.play_id + '&goback=0&subplayid=' + item.sub_play_id;
            router.push(url);
          }
        } else {
          url = `/${value.page}?playid=${value.id}&goback=0&subplayid=${value.default}`;
          router.push(url);
        }
      } else if (value.state === 4 && userInfo.value.fict_flag) {
        const url = `/${value.page}?playid=${value.id}&goback=0&subplayid=${value.default}`;
        router.push(url);
      } else if (value.state === 4 && !userInfo.value.fict_flag) {
        mb_toast(mb_t('当前彩种正处于内测阶段，敬请期待'));
      }
    })
    .catch(err => {
      loading?.close();
      if (err?.info) {
        mb_err(replace(err.info), 2);
      }
    });
};

// 电子的数据组装
export const dealDZList = data => {
  const arr = [];
  const _length = data !== undefined ? data.length : 0;
  const tenantState = useTenantStore();
  const { imgDomain } = storeToRefs(tenantState);
  for (let i = 0; i < _length; i++) {
    let subGamesItem = data[i];
    let playName = subGamesItem.upper_id;
    let smallClass = subGamesItem.play_id;
    if (typeof playName === 'number') playName = String(playName);
    if (playName > 4) {
      playName = playName.substr(0, 4);
      if (playName === '340') {
        smallClass = subGamesItem.play_name;
      }
      if (playName === '571') {
        const { play_name, upper_id } = subGamesItem;
        smallClass = `${upper_id}${play_name}`;
      }
    }
    let gameImgUrl = `${imgDomain.value}staticQ/dianzi/${playName}/game_${smallClass}.png`;
    if (subGamesItem.state !== 3) {
      arr.push({
        ...subGamesItem,
        playId: subGamesItem.play_id,
        gtype: subGamesItem.gtype,
        gameId: subGamesItem.upper_id || subGamesItem.upper_play_id,
        gameKey: subGamesItem.play_name,
        gameName: subGamesItem.play_cname,
        gameImgUrl: gameImgUrl,
        gameState: subGamesItem.state
      });
    }
  }
  return arr;
};

// 棋牌、电子 图片路径
export const getQPDZImg = item => {
  let lang = i18n.global.locale === 'cn' || i18n.global.locale === 'zh' ? 'cn' : 'en';
  const glDZGgame = game3Config.gameArr.dz;
  const qipai = game3Config.gameArr.qipai;
  const videoArr = game3Config.gameArr.video;
  const tiyuArr = game3Config.gameArr.tiyu;
  let imgUrl = '';
  item.upper_play_id = item.upper_play_id || item.upper_id || item.play_id;
  const tenantState = useTenantStore();
  const { imgDomain } = storeToRefs(tenantState);
  if (determineType(item.upper_play_id) === 'caipai') {
    let play = config.getPlayInfo(item.play_id);
    if (play) {
      imgUrl = imgDomain.value + 'staticQ/caipiao/default/' + lang + '/icon_play_' + (play.icon !== undefined ? (item.play_cat === 4 && play.base ? play.base + '_XY' : play.icon) : play.id) + '.png';
    }
  } else if (determineType(item.upper_play_id) === 'buyu') {
    imgUrl = `${imgDomain.value}staticQ/buyu/${item.upper_play_id}/${lang}/${'game_' + item.play_id}.png`;
  } else {
    imgUrl = `${imgDomain.value}staticQ/${determineType(item.upper_play_id)}/${item.upper_play_id}/game_${item.upper_play_id === 340 ? item.play_name : item.play_id}.png`;
  }
  if ((+item.upper_play_id === 506 && +item.play_id !== 506) || Number(item.upper_play_id) === 745) {
    imgUrl = `${imgDomain.value}staticQ/qipai/${item.upper_play_id}/${lang}/game_${item.play_id}.png`;
  }
  if (+item.upper_play_id === 750) {
    imgUrl = `${imgDomain.value}staticQ/tiyu/${item.upper_play_id}/${lang}/game_${item.play_id}.png`;
  }
  if (Number(item.upper_play_id) === 504 || Number(item.upper_play_id) === 468) {
    imgUrl = `${imgDomain.value}staticQ/caipiao/${item.upper_play_id}/${'game_' + item.play_id}.png`;
    if (Number(item.upper_play_id) === 504) {
      imgUrl = `${imgDomain.value}staticQ/caipiao/${item.upper_play_id}/${lang}/${'game_' + item.play_id}.png`;
    }
    if (+item.play_id === 504) {
      imgUrl = `${imgDomain.value}staticQ/caipiao/468/game_504.png`;
    } else if (+item.play_id === 468) {
      imgUrl = `${imgDomain.value}staticQ/caipiao/468/game_468.png`;
    }
  }
  if (Number(item.play_id) === 1326) {
    imgUrl = `${imgDomain.value}staticQ/caipiao/default/${lang}/${'icon_play_' + item.play_id}.png`;
  }
  if (Number(item.upper_play_id) === 710 || Number(item.upper_play_id) === 706) {
    imgUrl = `${imgDomain.value}staticQ/caipiao/default/${lang}/${'icon_play_' + item.play_id}.png`;
  }
  if (glDZGgame.some(it1 => it1 === Number(item.upper_play_id))) {
    imgUrl = `${imgDomain.value}staticQ/dianzi/${item.upper_play_id}/${lang}/${'game_' + (item.upper_play_id === 340 ? item.play_name : item.play_id)}.png`;
  }
  if (videoArr.includes(item.upper_play_id) || tiyuArr.includes(item.upper_play_id)) {
    imgUrl = imgDomain.value + 'staticQ/caipiao/default/' + lang + '/icon_play_' + item.play_id + '.png';
  }
  if (qipai.some(it1 => it1 === Number(item.upper_play_id))) {
    imgUrl = `${imgDomain.value}staticQ/qipai/${item.upper_play_id}/${lang}/${'game_' + (item.upper_play_id === 340 ? item.play_name : item.play_id)}.png`;
  }
  return imgUrl || item.gameImgUrl;
};

const handleState = key => {
  let isSale = true;
  const gameStore = useGameStore();
  if (gameStore.playAllStates) {
    let item = gameStore.playAllStates[key];
    if (item && item.play) {
      if (item.play.play_state === 3) {
        isSale = false;
      }
    }
  }
  return !isSale;
};

const videoSportsJump = (info, id, isapp) => {
  const gameStore = useGameStore();
  const payload: any = {
    play_id: info.gameId
  };
  payload.play_id = id;
  if (id === 60) {
    payload.data = { type: 'AG' };
  } else if (id === 345) {
    payload.data = { type: 'live' };
  } else if (id === 400) {
    payload.data = { type: 'DG' };
  } else if (id === 402) {
    payload.data = { type: 'BGSX' };
  } else if (id === 412) {
    payload.data = { type: 'SMG_titaniumLiveGames_MP_Baccarat' };
  } else if (id === 420) {
    payload.data = { type: 'IGZR' };
  } else if (id === 395 || id === 342 || id === 981) {
    payload.data = { type: '1' };
  } else if (id === 409) {
    // 皇冠体育
    payload.data = { type: 'HG-pc' };
  } else if (id === 418) {
    // 胜博体育
    payload.data = { type: 'SBTY' };
  } else if (id === 1313) {
    // PRETTY真人
    payload.data = { type: '1' };
  }
  let loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
  gameStore
    .get3GameUrl(payload)
    .then((res: any) => {
      loading.close();
      if (res && res.url) {
        const _urlInfo = transferThirdGameUrl(res.url, payload);
        showConfirmDialog({
          message: '<div><div>' + mb_t('进入游戏') + '</div></div>',
          showConfirmButton: true,
          className: 'enter3gameDialog',
          overlayClass: 'enter3gameDialogoverlay',
          showCancelButton: true,
          allowHtml: true,
          closeOnPopstate: true,
          closeOnClickOverlay: false,
          confirmButtonText: mb_t('确定'),
          cancelButtonText: mb_t('取消')
        })
          .then(() => {
            if (isapp) {
              mb_RNEvent.open3Game(_urlInfo.url, info);
            } else {
              goLinkGame(_urlInfo);
            }
          })
          .catch(() => {
            close3Game(payload.play_id);
          });
      } else {
        mb_err(mb_t('未获取到三方平台地址'));
      }
    })
    .catch(err => {
      loading.close();
      mb_err(err.toString());
    });
};

const jumpDZ3Game = (info, isapp) => {
  const gameStore = useGameStore();
  let data: any = {};
  data.type = info.gameKey;
  if (String(info.gameId).substr(0, 3) === '340') {
    info.gameId = 340;
    data.gtype = info.gtype;
  }
  info.play_id = info.playId;
  if (info.play_id === 1313) {
    // PRETTY真人
    data.type = '1';
  }
  if (info.play_id === 1384) {
    data.isMobileOpen = 1
  }
  const payload = {
    data,
    play_id: info.gameId
  };
  let loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
  gameStore
    .get3GameUrl(payload)
    .then((res: any) => {
      loading.close();
      if (res && res.url) {
        const _urlInfo = transferThirdGameUrl(res.url, payload);
        showConfirmDialog({
          message: '<div><div>' + mb_t('进入游戏') + '</div>' + '</div></div>',
          showConfirmButton: true,
          showCancelButton: true,
          className: 'enter3gameDialog',
          overlayClass: 'enter3gameDialogoverlay',
          allowHtml: true,
          closeOnPopstate: true,
          closeOnClickOverlay: false,
          confirmButtonText: mb_t('确定'),
          cancelButtonText: mb_t('取消')
        })
          .then(() => {
            if (isapp) {
              if (info.gameId === 405) {
                // 如果是DT电子的话，就在url拼上 speCode=1
                _urlInfo.url = `${_urlInfo.url}&speCode=1`;
              }
              mb_RNEvent.open3Game(_urlInfo.url, info);
            } else {
              goLinkGame(_urlInfo);
            }
          })
          .catch(() => {
            close3Game(payload.play_id);
          });
      } else {
        mb_err('未获取到三方平台地址');
      }
    })
    .catch(err => {
      loading.close();
      mb_err(err?.info);
    });
};

const goLinkGame = info => {
  if (info.target === '_self') {
    router.push('/iframe');
  } else {
    window.open(info.url);
  }
};
const close3Game = async thirdGameId => {
  const sportsStore = useSportsStore();
  await sportsStore.reqTransitionAllYe({
    play_id: thirdGameId,
    type: 3
  });
  setTimeout(() => {
    sportsStore.reqBlanceCollect();
  }, 1000);
};

const jump3Game = info => {
  const gameStore = useGameStore();
  let data = {} as any;
  if (info.playId === 504) {
    data.isMobileOpen = 1;
    data.type = info.play_name;
  }
  if ((info.playId || info.play_id) === 1326) {
    data.type = 1;
  } else {
    data.type = info.code || info.play_name;
  }
  info.gameId = info.upperId;
  const payload = {
    data: data,
    play_id: info.upperId || info.play_id
  };
  if (payload.play_id === 1313) {
    // PRETTY真人
    payload.data.type = '1';
  }
  if ([1384, 1389].includes(payload.play_id)) {
    payload.data.isMobileOpen = 1
  }
  if (payload.play_id === 1388) {
    payload.data.type = '200';
  }
  let loading = mb_loading({ message: mb_t('加载中'), forbidClick: true, duration: 0 });
  gameStore
    .get3GameUrl(payload)
    .then((res: any) => {
      loading.close();
      if (res && res.url) {
        console.log("payloadpayload", payload, res, info)
        const _urlInfo = transferThirdGameUrl(res.url, payload);
        showConfirmDialog({
          message: '<div><div>' + mb_t('进入游戏') + '</div></div>',
          showConfirmButton: true,
          showCancelButton: true,
          className: 'enter3gameDialog',
          overlayClass: 'enter3gameDialogoverlay',
          allowHtml: true,
          closeOnPopstate: true,
          closeOnClickOverlay: false,
          confirmButtonText: mb_t('确定'),
          cancelButtonText: mb_t('取消')
        })
          .then(() => {
            if (isApp()) {
              mb_RNEvent.open3Game(_urlInfo.url, info);
            } else {
              goLinkGame(_urlInfo);
            }
          })
          .catch(() => {
            close3Game(payload.play_id);
          });
        info._params = { data, play_id: info.upperId };
      }
    })
    .catch(err => {
      loading.close();
      if (err.exMessage) {
        mb_err(err.exMessage.toString());
      } else if (err.info) {
        mb_err(err.info.toString());
      }
    });
};

export const playChatGame = (playid, url, fn = null, changePage = true) => {
  const gameStore = useGameStore();
  let play = config.getPlayInfo(playid);
  gameStore
    .reqChatByNames({
      play_names: play.play_name
    })
    .then(res => {
      if (res) {
        let roomType = null;
        for (let key in res) {
          let itemType = res[key];
          if (!roomType) {
            roomType = itemType;
          }
          if (Number(itemType.room_type_id) === Number(gameStore.roomTypeId)) {
            roomType = itemType;
            break;
          }
        }
        // 设置房间类型
        if (roomType) {
          if (roomType.playRooms) {
            let room = null;
            for (let i = 0; i < roomType.playRooms.length; i++) {
              let roomItem = roomType.playRooms[i];
              if (!room) {
                room = roomItem;
              }
              if (Number(roomItem.play_room_id) === Number(gameStore.roomId)) {
                room = roomItem;
                break;
              }
            }
            if (room) {
              gameStore.setCurRoomType(roomType);
              setJSON('curRoomType', roomType, true);
              gameStore.setCurRoom(room);
              if (changePage) {
                if (fn) {
                  fn(room)
                } else {
                  router.push(url);
                }
              }
            }
          }
        }
      }
    })
    .catch(err => {
      mb_err(err.info, 2);
    });
};

const getQiPaiPlay = qiPaiPlayGames => {
  let arr = [];
  if (qiPaiPlayGames.length) {
    qiPaiPlayGames.forEach(item => {
      if (+item.state !== 3) {
        arr.push(item);
      }
    });
  }
  return arr;
};

// 处理IM体育在不同代理盘下的名称显示
// 主盘取租户名字，tenant_name
// 代理盘1取  tenant_des
// 代理盘2取 xmpp
export const formatIMSport = name => {
  const { hotData } = useHot();
  const { tenantInfo } = hotData;
  const proxy = Number(tenantInfo.value.proxy);
  let reName = name;
  if (name === 'IM体育') {
    if (proxy === 0) {
      reName = tenantInfo.value.tenant.tenant_name;
    } else if (proxy === 1) {
      reName = tenantInfo.value.tenant.tenant_des;
    } else if (proxy === 2) {
      reName = tenantInfo.value.tenant.xmpp;
    }
  }
  return mb_t(reName);
};
export const formatIMLogo = (src, item) => {
  let url = mb_getLogoById(28);
  let reUrl = '';
  if (item.play_id === 931) {
    reUrl = url;
  } else {
    reUrl = src;
  }
  return reUrl;
};
