import CryptoJS from 'crypto-js';
import { useXianLuStore } from '/@/kernel-mn/stores/xianlu';
import { useUserStore } from '/@/kernel-mn/stores/user';
import { useTenantStore } from '/@/kernel-mn/stores/tenant';
import { FBQRecordEvent } from './appsFlyer';
import router from '../router';
import { useHot } from "/@/kernel-mn/stores/useHot";
let domain_addr = location.origin;
// @ts-ignore
if (import.meta.env.DEV) {
  // @ts-ignore
  domain_addr = import.meta.env.VITE_API_DOMAIN_ADDR;
}
const key = '0d3389d9dea66ae1';

const encrypt = text => {
  // @ts-ignore
  return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
};
const decrypt = text => {
  // @ts-ignore
  let decrypted = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export default class Socket {
  connectNum = 1;
  webSocket = null;
  lockReconnect = false;
  timeout = 10 * 1000;
  timeoutObj = null;
  serverTimeoutObj = null;
  timeoutnum = null;
  globalCallback = null;
  groupId = [];
  isConnect = false;
  connectINdex = 0;
  xianlu = [];
  userInfo = {
    cust_id: 0
  };
  protocol = '';
  tenantInfo = {
    tid: 0
  };
  initargs = { urlObj: { requestType: 2 }, isreset: false }; // 初始传入的参数
  constructor() {
    this.xianlu = useXianLuStore().chat;
    this.userInfo = useUserStore().userInfo;
    this.tenantInfo = useTenantStore().tenantInfo;
    this.protocol = domain_addr.toString().split('//')[0];
  }

  // 初始化websocket isreset是否断掉重连
  initWebSocket(urlObj = { requestType: 2 }, isreset = false) {
    const that = this;
    const xlList = this.xianlu;
    const wsPre = this.protocol === 'http:' ? 'ws://' : 'wss://';
    let url: string;
    this.initargs.urlObj = urlObj;
    this.initargs.isreset = isreset;

    if ('WebSocket' in window) {
      if (xlList[this.connectINdex] && xlList[this.connectINdex].domain_addr) {
        let addrs = xlList[this.connectINdex]?.domain_addr?.split('//');
        url = `${wsPre}${addrs[1] || addrs[0]}/?userId=${this.userInfo.cust_id || 0}&type=1&tId=${this.tenantInfo.tid}&requestType=${urlObj.requestType}`;
        this.webSocket = new WebSocket(url);
        // 打开
        this.webSocket.onopen = function () {
          that.webSocketOpen();
          console.log('mywebSocket:open');
        };
        // 收信
        this.webSocket.onmessage = function (e) {
          that.webSocketOnMessage(e, urlObj);
        };
        // 关闭
        this.webSocket.onclose = function () {
          that.webSocketClose();
          console.log('mywebSocket:close');
          if (isreset) {
            console.log('mywebSocket:reconnect');
            that.reconnect();
          }
        };
        // 错误
        this.webSocket.onerror = function () {
          console.log('mywebSocket:onerror');
          // that.reconnect(); // app.vue 有判断断掉重连
        };
      } else {
        this.connectINdex = 0;
      }
    } else {
      alert('该浏览器不支持websocket!');
    }
  }

  // 连接socket建立时触发
  webSocketOpen() {
    this.start();
  }

  webSocketOnMessage(obj, urlObj = { requestType: 5 }) {
    let data = decrypt(JSON.parse(obj.data)) || '{}';
    let pdata = JSON.parse(data);
    // 充值成功后发送的数据
    if (pdata.code === 10000 && pdata.commInt === 121 && pdata.command === 'COMMAND_GETCHARGE') {
      const { rechargeSuccess, setRechargeSuccessData } = useHot();
      console.log(pdata, 'recharge.ws.data');
      // 充值类型 charge_type 0:线上 1线下 2手工
      let { charge_amount, charge_num, charge_type, cust_id, tid } = pdata.data || {};
      const amount = +((charge_amount || 0) / 1000).toFixed(3);
      const number = charge_num || 0;
      // 如果本地有数据则表示收到重复了
      if (rechargeSuccess.value) {
        // 收到重复的时候删除ws数据
        this.sendSock({ cust_id, tid, cmd: 121 });
        return false
      }
      // 将本次充值的数据保存起来 并弹窗提示用户 后删除数据
      setRechargeSuccessData({ amount, number, charge_type, cust_id });
      // 收到数据 当次充值数据 删除数据
      this.sendSock({ cust_id, tid, cmd: 121 });
      // 埋点发送开始
      if (number === 1) {
        FBQRecordEvent('chargeSuccess', { isFirstDeposit: 1, amount: amount });
      } else if (number > 1) {
        FBQRecordEvent('chargeSuccess', { isFirstDeposit: 0, amount: amount });
      }
    }
    // 提现成功后发送的数据
    if (pdata.code === 10000 && pdata.commInt === 123 && pdata.command === 'COMMAND_GETWITHDRAW') {
      const { withdrawSuccess, setWithdrawSuccessData } = useHot();
      console.log(pdata, 'withdraw.ws.data');
      // 提现类型 withdrw_type 1:成功 2拒绝
      let { withdraw_amount, withdrw_type, cust_id, tid } = pdata.data || {};
      const amount = +((withdraw_amount || 0) / 1000).toFixed(3);
      // 如果本地有数据则表示收到重复了
      if (withdrawSuccess.value) {
        // 收到重复的时候删除ws数据
        this.sendSock({ cust_id, tid, cmd: 123 });
        return false
      }
      // 将本次提现的数据保存起来 并弹窗提示用户 后删除数据
      setWithdrawSuccessData({ amount, withdrw_type, cust_id });
      // 收到数据 当次提现数据 删除数据
      this.sendSock({ cust_id, tid, cmd: 123 });
    }
    // 获取ws登录
    // if (pdata.commInt === 6) {}
    // 只有心跳更新心跳
    if (pdata.commInt === 23 || pdata.commInt === 6) {
      this.reset(urlObj);
    }
  }

  // 发送数据
  webSocketSend(data) {
    this.webSocket.send(JSON.stringify(data));
  }

  // 关闭socket
  webSocketClose(val = 'close') {
    this.timeoutObj && clearTimeout(this.timeoutObj);
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
    if (val === 'close') {
      this.webSocket && this.webSocket.close();
    } else {
      this.reconnect();
    }
  }

  // 先判断状态后续加心跳
  sendSock(data) {
    data.createTime = '';
    data = encrypt(JSON.stringify(data));
    if (!this.webSocket) {
      return;
    }
    // 此处先判断socket连接状态
    const that = this;
    switch (this.webSocket.readyState) {
      case this.webSocket.CONNECTING:
        setTimeout(function () {
          that.webSocketSend(data);
        }, 1000);
        break;
      case this.webSocket.OPEN:
        that.webSocketSend(data);
        break;
      case this.webSocket.CLOSING:
        setTimeout(function () {
          that.webSocketSend(data);
        }, 0);
        break;
      case this.webSocket.CLOSED:
        console.log('关了');
        break;
      default:
        setTimeout(function () {
          that.webSocketSend(data);
        }, 1000);
        break;
    }
  }

  // 重新连接
  reconnect() {
    if (this.lockReconnect) {
      return;
    }
    this.connectNum++;
    // this.connectINdex++; // 重连当前ws地址
    this.lockReconnect = true;
    this.timeoutnum && clearTimeout(this.timeoutnum);
    this.timeoutnum = setTimeout(() => {
      let { urlObj, isreset } = this.initargs;
      this.webSocketClose('close');
      this.initWebSocket(urlObj, isreset);
      this.lockReconnect = false;
    }, 10000);
  }

  // 重置心跳
  reset(urlObj) {
    const that = this;
    clearTimeout(that.timeoutObj);
    clearTimeout(that.serverTimeoutObj);
    that.start(urlObj);
  }

  // 开启心跳
  start(urlObj = { requestType: 2 }) {
    const that = this;
    that.timeoutObj && clearTimeout(that.timeoutObj);
    that.serverTimeoutObj && clearTimeout(that.serverTimeoutObj);
    that.timeoutObj = setTimeout(function () {
      if (that.webSocket && that.webSocket.readyState === 1) {
        const data = {
          userId: that.userInfo.cust_id || 0,
          cmd: 13,
          tId: that.tenantInfo.tid,
          requestType: urlObj.requestType,
          time: new Date().getTime()
        };
        that.webSocket.send(encrypt(JSON.stringify(data)));
      } else {
        that.reconnect();
      }
      that.serverTimeoutObj = setTimeout(function () {
        that.webSocketClose();
      }, that.timeout);
    }, that.timeout);
  }
}

export const G_goLogin = (params = null) => {
  router.push(params ? params : '/login');
};

export const G_goRegister = (params = null) => {
  router.push(params ? params : '/register');
};

// eslint-disable-next-line no-unused-vars
export const banlance2str = (balance, digital = 2) => {
  if (isNaN(balance)) {
    return '0';
  }
  return (Math.round(+balance || 0) / 1000).toFixed(digital).toString();
};

// 隐藏名字
export const maskName = (str) => {
  if (!str) {
    return '';
  }
  const regex = /^(.{2})(.*)(.{3})$/;
  const maskedNumber = str.replace(regex, (_, first, middle, last) => {
    const maskedMiddle = '***';
    return `${first}${maskedMiddle}${last}`;
  });
  return maskedNumber;
};

// 得到当前租户 如：17-6
export const getCurrentTenant = () => {
  const tenant = useTenantStore().tenantInfo;
  let tenant_clusters_id: any = tenant.clusters_id;
  let tenant_tid: any = tenant.tid;
  const localTenant = window.sessionStorage.getItem('CIDTID');
  if (localTenant) {
    return localTenant
  }
  return `${tenant_clusters_id}-${tenant_tid}`;
};

// 判断当前租户是否为传入租户 如：17-6
export const isSpecialTenant = (clusters_id, tid) => {
  if (!clusters_id || !tid) {
    return false;
  }
  return `${clusters_id}-${tid}` === getCurrentTenant();
};