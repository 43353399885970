// 页面接口操作方法
import { useGameStore } from '/@/kernel-mn/stores/game';
import { useSportsStore } from "/@/kernel-mn/stores/sports";
import config from "/@/kernel-mn/config";
import { isAndroid, isIOS } from "/@/kernel-mn/utils";
import { useHaxiStore } from "/@/kernel-mn/stores/haxi";
import { mb_err, mb_loading, mb_t } from "/@/plugins/global";
import { ref, toRaw } from "vue";
import { storeToRefs } from "pinia";

// 初始化game
export const initSportGame = () => {
  const gameStore = useGameStore();
  gameStore.initSportGame();
};

export const getChanCon = () => {
  let chan_con = 8
  if ((isIOS()) && config.appVersion > 0) {
    chan_con = 2
  }
  if ((isAndroid()) && config.appVersion > 0) {
    chan_con = 1
  }
  return chan_con
}
// 合并接口 issc 是否是收藏调用
export const getTotalGamesPlatform = (issc = false) => {
  const gameStore = useGameStore();
  return gameStore.getTotalGamesPlatform({ chan_con: getChanCon() }, true).then(() => {
    // 开启了彩票 就掉用户彩票配置
    if (!issc) { // 为假为app初始化调用
      if (gameStore.isOpenCp) {
        getConfigJson()
      }
    }
  }); // 首页数据
};
// 收藏和取消收藏 可以调用这个
export const getGamesPlatform = () => {
  const gameStore = useGameStore();
  return gameStore.getGamesPlatform()
}
// 用户彩票配置
export const getConfigJson = () => {
  const gameStore = useGameStore();
  return gameStore.reqConfigJson();
};
// 回收所有余额 包括第三方
export const getTransitionAllYe = (payload = {}, showloading = true, loadDom = ref(false), fn = () => { }, showToast = false) => {
  const sportsStore = useSportsStore();
  const loading = showloading ? mb_loading({}) : null; // 是否显示loading
  sportsStore.reqTransitionAllYe(payload).then(() => {
    loadDom.value = true;
    getBlanceCollect(showloading, loadDom, fn);
    showToast && mb_err(mb_t('操作成功'), 2);
  }).catch(err => {
    if (err && err.code === "1002") {
      getBlanceCollect(showloading, loadDom, fn);
    } else {
      showToast && mb_err(err.info, 2);
    }
  }).finally(() => {
    loading?.close();
    loadDom.value = false;
  });
};
// 刷新余额
export const getBlanceCollect = (showloading = true, loadDom = ref(false), fn = () => { }) => {
  const sportsStore = useSportsStore();
  const loading = showloading ? mb_loading({}) : null; // 是否显示loading
  sportsStore.reqBlanceCollect().finally(() => {
    loading?.close();
    loadDom.value = false;
    fn && fn();
  });
};
// 获取用户的钱
export const getUserBlance = (showloading = false) => {
  const sportsStore = useSportsStore();
  const { blance } = storeToRefs(sportsStore);
  if (!blance.value) {
    getBlanceCollect(showloading);
  };
  return blance;
};
// 获取用户的余额+第三方余额明细
export const getBlanceAllYe = (payload = {}) => {
  const sportsStore = useSportsStore();
  return sportsStore.reqBlanceAllYe(payload);
};
// 回收指定的第三方余额
export const getTransitionOne = (payload = {}) => {
  const sportsStore = useSportsStore();
  return sportsStore.reqTransitionOne(payload);
}
// 划转到第三方余额
export const getTransitionOther = (payload = {}) => {
  const sportsStore = useSportsStore();
  return sportsStore.reqTransitionOther(payload);
}
// 检测第三方
export const checking3Games = (payload: any = {}) => {
  const gameStore = useGameStore();
  return gameStore.checking3Game({ play_id: payload.play_id });
};
// 获取用户的钱
export const getUserFreeBlance = () => {
  const sportsStore = useSportsStore();
  return +(sportsStore.freezeBalance / 1000).toFixed(3);
};
// 获取彩票列表
export const getHallList = (payload = { play_cat: 1, size: 16, type: 1 }, force = false) => {
  const gameStore = useGameStore();
  let hallData = gameStore.hallData || [];
  if (!hallData.length || force) {
    return gameStore.purchaseHall(payload);
  }
}
// 获取全部彩票 force为真强制请求
export const getHallListSync = async (payload: any = { type: 0 }, force = false) => {
  const gameStore = useGameStore();
  const hallDataAll = gameStore.hallDataAll || [];
  const saveGPIList = gameStore.saveGPIList || [];
  const saveDBList = gameStore.saveDBList || [];
  payload = { play_cat: 1, size: 16, type: 0, ...payload };
  // payload.type === 17 && (payload.type = 16); // GPI是16
  // 为真强制请求
  if (!force) {
    if (payload.type === 0) {
      const data: any = toRaw(hallDataAll);
      data.old_type = payload.type;
      if (hallDataAll.length) return Promise.resolve(data);
    };
    // 如果是GPI彩票
    if (payload.type === 16) {
      const data: any = toRaw(saveGPIList);
      data.old_type = payload.type;
      if (saveGPIList.length) return Promise.resolve(data);
    };
    // 如果是DB彩票
    if (payload.type === 31) {
      const data: any = toRaw(saveDBList);
      data.old_type = payload.type;
      if (saveDBList.length) return Promise.resolve(data);
    };
  }
  try {
    const data: any = await gameStore.purchaseHall(payload);
    // 跟数据回来type一致 才做事情
    if (data.old_type === payload.type) {
      // 过滤 暂停销售的彩种
      let newData = data.filter((item: any) => (![3, 9].includes(item.play_state)));
      // 排序
      newData.sort((a: any, b: any) => {
        const top = b.is_top - a.is_top;
        const hot = b.hot_val - a.hot_val;
        if (top === 0) { // 一样的话再根据 hot_val 排列
          if (hot === 0) { // 一样的话再根据 sub_play_id 排列 第三方彩sub_play_id为0，所以使用play_id
            return (b.sub_play_id || b.play_id) - (a.sub_play_id || a.play_id);
          } else {
            return hot;
          }
        } else {
          return top;
        }
      });
      if (payload.type === 0) {
        gameStore.setHallDataAll(newData);
      };
      newData.old_type = data.old_type;
      return Promise.resolve(newData);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};
// 通过play_name 获取游戏最近开奖记录和当前期数数据 play_name,play_name,... 多个逗号隔开
export const getCPInfoByPlayName = (payload = { play_name: '' }) => {
  const gameStore = useGameStore();
  return gameStore.reqCPInfoByPlayName(payload);
};
// 获取PC蛋蛋数据
export const getPC28List = (payload = { play_cat: 1, size: 16, type: 2 }, force = false) => {
  const gameStore = useGameStore();
  let savePC28 = gameStore.savePC28 || [];
  if (!savePC28.length || force) {
    return gameStore.purchaseHall(payload);
  } else {
    Promise.resolve(savePC28);
  }
}
// 获取原创游戏
export const getOriginalGames = () => {
  const gameStore = useGameStore();
  return gameStore.reqOriginalGames();
}
// 获取哈希游戏
export const getGameList = () => {
  const haxiStore = useHaxiStore();
  return haxiStore.getGameList();
}
// 按天统计投注历史记录
export const getBetHistoryByDay = (payload = {}) => {
  const gameStore = useGameStore();
  return gameStore.reqBetHistoryByDay(payload);
}
// 一般投注的自定义金额获取与设置
export const customBallAmount = () => {
  const gameStore = useGameStore();
  let { selectedBallsAmount }: any = storeToRefs(gameStore);
  // 清空配置
  const setSelectedBallsAmount = () => {
    selectedBallsAmount.value = {};
  }
  return { selectedBallsAmount, setSelectedBallsAmount };
}
// 获取赔率说明
export const getPeiLvData = (payload = {}) => {
  const gameStore = useGameStore();
  return gameStore.reqPeiLvData(payload);
}
