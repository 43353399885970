<template>
  <ComRepair v-if="isIpLimited || systemRepair" :systemRepair="systemRepair" :ipLimit="isIpLimited" />
  <template v-else>
    <div v-if="!pageLoaded || !tenantInfo.tid" :class="THEME" class="page-loading border-box d-flex flex-column align-items-center justify-content-center py-40">
      <van-loading size="1.5rem" color="#1989fa" />
      <van-button v-if="showRefshBtn" style="margin-top: 0.25rem; width: 2rem" class="btn-success" @click="reloadWindows">{{ $t('刷新') }}</van-button>
      <p v-if="showRefshBtn" class="p-2" v-text="`URI:${origin}, Code: ${errInfo.code || '--'}`"></p>
    </div>
    <router-view v-else v-slot="{ Component }" :class="[THEME]" :theme="THEME" :cidtid="CIDTID" :tid="tenantInfo.tid">
      <transition name="van-fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </template>
  <!--部分全局弹窗当做这个里面-->
  <div id="app-container" class="app-pop-dom" :class="[THEME]"></div>
  <!--升级弹窗-->
  <div class="updateModal" v-if="updateflag">
    <div class="updateContent">
      <div class="updateTop">
        <div class="updateTitle">{{ $t('更新提示') }}</div>
        <div class="uContent">
          <span>{{ $t('当前版本号') }}：{{ appVerName }}</span>
          <span>{{ $t('最新版本号') }}：{{ appUpdateVersion }}</span>
          <span>{{ $t('本次更新内容') }}：</span>
          <span>{{ appUpdateContent }}</span>
        </div>
      </div>
      <div class="updateBottom">
        <span v-if="mandatoryUpate === 0" @click="changeUpdateFlag(false)">{{ $t('稍后更新') }}</span>
        <div :class="mandatoryUpate === 0 ? 'updateBtn' : 'updateBtn mandatoryeBtn'">
          <span @click="updateAppVersion('down')">{{ $t('官网下载') }}</span>
          <span class="red" @click="updateAppVersion('now')">{{ $t('立即更新') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, onUnmounted, provide, ref, watch} from 'vue';
import { storeToRefs } from 'pinia';
import { useTenantStore } from '/@/kernel-mn/stores/tenant';
import {autoXianLu} from '/@/hooks/getXianlu';
import config from '/@/kernel-mn/config';
import { useHot } from '/@/kernel-mn/stores/useHot';
import { removeLastNotice } from '/@/hooks/message';
import {mb_RNEvent, mb_t} from '/@/plugins/global';
import { isAndroid, isApp, isIOS } from '/@/kernel-mn/utils';
// @ts-ignore
import $ from 'jquery';
import {useRoute, useRouter} from "vue-router";
import {showDialog} from "vant";
import {currencyMoney} from "/@/hooks/common";
import {getTotalGamesPlatform, getTransitionAllYe, initSportGame} from "/@/hooks/gameActions";
import {getSession} from "/@/kernel-mn/utils/storage";
import {mb_getLogoById} from "/@/hooks/publicData";
import { THEMES_NAME } from '/@/config/';
export default defineComponent({
  name: 'App',
  setup() {
    // 初始化参数
    config.init();
    const route = useRoute();
    const router = useRouter();
    const tenantState = useTenantStore();
    const {hotData: {userInfo, isLogined}, rechargeSuccess, setRechargeSuccessData, withdrawSuccess, setWithdrawSuccessData, tResLoc} = useHot();
    // ip限制和维护
    let isIpLimited = computed(() => tenantState.IPLimit);
    let systemRepair = computed(() => tenantState.systemRepair);

    let showRefshBtn = ref(false);
    let pageLoaded = ref(false); // 页面是否加载完成[合并接口是否返回]
    let errInfo:any = ref({});
    removeLastNotice(); // 清空公告id
    initSportGame(); // 初始化游戏
    tenantState.reqAseq();
    tenantState.fetchDefaultConfig().then(res => {
      if (!res) return (showRefshBtn.value = true);
      localStorage.setItem("PLATFORM_AREA", tenantState?.tenantInfo?.area)
      let htmlEncode = (str) => {
        let arrEntities = {'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'};
        return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, (all,t) => arrEntities[t]);
      }
      let {CUST_HTML_STR}:any = tenantState.tenantInfo.sysOptions;
      let {description, footercode, headercode, keywords}:any = CUST_HTML_STR? JSON.parse(CUST_HTML_STR): {};
      // 一些用户自定义代码 --start--
      keywords && ((document.getElementById('meta-keywords') as any).content = keywords);
      description && ((document.getElementById('meta-description') as any).content = description);
      headercode && $('#header-code').html(htmlEncode(headercode));
      footercode && $('#footer-code').html(htmlEncode(footercode));
      // 一些用户自定义代码 --end--
      !res.listShow && tenantState.reqActiveConfiguration(); // 在371130中返回了
      if (tenantState.tenantInfo.tenant?.tenant_name) {
        document.title = tenantState.tenantInfo.tenant.tenant_name;
      }
      let iconurl = mb_getLogoById(30);
      if (iconurl) {
        (document.getElementById('webIco') as any).href = iconurl;
      }
      // 首页数据
      getTotalGamesPlatform().then(() => {
        pageLoaded.value = true;
      }).catch(() => {
        // 加载失败 则显示刷新按钮
        showRefshBtn.value = true;
      });
    }).catch((err) => {
      errInfo.value = err;
      showRefshBtn.value = true;
    });
    const { tenantInfo } = storeToRefs(tenantState);

    const reloadWindows = () => {
      // window.location.href = location.href;
      window.location.reload();
    };
    // 返回ws链接数据
    const getWSdata = () => {
      let wsdata = { requestType: 5 }; // 体育是5
      return wsdata;
    };
    let socket = ref(null);
    const connectWS = () => {
      socket.value && socket.value.webSocketClose();
      autoXianLu(getWSdata()).then(res => {
        socket.value = res; // 线路请求 and websocket
      })
    };
    tenantInfo.value.tid && connectWS();
    watch(
      () => tenantInfo.value.tid,
      () => {
        connectWS()
      }
    );
    watch(
      () => userInfo.value.cust_id, () => {
        connectWS()
      }
    );
    watch(
      () => route.path,
      () => {
        // 不是链接状态 就链接
        if (socket.value && socket.value.webSocket?.readyState === 3) {
          connectWS();
        }
        // 埋点 插入到脚部代码用的
        // @ts-ignore
        window.KWAIQEvent && window.KWAIQEvent.view('view');
        // @ts-ignore
        window.FBQEvent && window.FBQEvent.view('view');
      }
    );
    // ws返回的充值结果提示弹窗
    watch(rechargeSuccess, nv => {
      if (nv) {
        let { amount } = nv;
        showDialog({
          title: mb_t('充值成功'),
          message: `${mb_t('充值成功')}，${mb_t('金额')}: ${currencyMoney(amount)}`,
          confirmButtonText: mb_t('确认')
        }).then(() => {
          setRechargeSuccessData(null);
          // 如果是内嵌充值页面 点确定后 返回上一页
          if (route.path === '/jumpurl') router.back();
        });
      }
    });
    // ws返回的提现结果提示弹窗
    watch(withdrawSuccess, nv => {
      if (nv) {
        // 提现类型 withdrw_type 1:成功 2拒绝
        let { amount, withdrw_type } = nv;
        let msg = '', title = '';
        if (+withdrw_type === 1){
          title = mb_t('提现成功');
          msg = `${mb_t('提现成功')}，${mb_t('金额')}: ${currencyMoney(amount)}`;
        } else if (+withdrw_type === 2) {
          title = mb_t('提现拒绝');
          msg = `${mb_t('提现拒绝')}，${mb_t('查看')} ${mb_t('站内信')}`;
        }
        showDialog({
          title: title,
          message: msg,
          confirmButtonText: mb_t('确认')
        }).then(() => {
          setWithdrawSuccessData(null);
        });
      }
    });

    watch(tResLoc, theme => {
      if (!THEMES_NAME.includes(theme)) {
        theme = THEMES_NAME[0]
        tenantState.setTheme(theme)
      }
      const body = document.body;
      if (!body.classList.contains(theme)) {
        body.className = theme;
      }
    })
    onMounted(() => {
      if (!window.localStorage.getItem('BACKGROUND_MUSIC')) {
        window.localStorage.setItem('BACKGROUND_MUSIC', '0')
      }

      if (!window.localStorage.getItem('CLICK_MUSIC')) {
        window.localStorage.setItem('CLICK_MUSIC', '1')
      }
    })
    onUnmounted(() => {
      socket.value && socket.value.webSocketClose();
    });
    provide('WEBWS', socket); // 将socket传递到下层
    // 更新弹窗逻辑
    let updateflag = ref(false);
    let appVersion = ref(0);
    let newAppVersion = ref(0);
    let mandatoryUpate = ref(0);
    let updateType = ref(0); // 0 直接更新   1下载更新
    let chan_code = ref('MWEB');
    let appUpdateVersion = ref('');
    let appUpdateContent = ref('');
    let updateUrl = ref('');
    let appVerName = computed(() => {
      let name = '';
      name = 'V1.0.' + appVersion.value;
      return name;
    });
    let downUrl = computed(() => {
      let downUrl = '';
      if (tenantInfo.value) {
        if (tenantInfo.value.tenant && tenantInfo.value.tenant.official_addr) {
          downUrl = tenantInfo.value.tenant.official_addr;
        }
      }
      return downUrl;
    });
    const changeUpdateFlag = val => {
      updateflag.value = val;
    };
    const updateAppVersion = val => {
      if (val === 'down' || updateType.value === 1) {
        let url = updateUrl.value ? updateUrl.value : downUrl.value;
        if (isApp()) {
          mb_RNEvent.sendNativeMsg('url:' + url);
        } else {
          window.open(url);
        }
        updateflag.value = false;
      } else if (val === 'now') {
        mb_RNEvent.sendNativeMsg('updateVersion');
      }
      appVersion.value = newAppVersion.value;
    };
    mb_RNEvent.sendNativeMsg('getVersion');
    mb_RNEvent.addObserver(`aVersionKey`, 'aVersion', e => {
      appVersion.value = e.appVersion;
      chan_code.value = e.chanCode;
    });
    // mb_RNEvent.addObserver(`${mb_RNEvent.rnConfigData}Key`, mb_RNEvent.rnConfigData, data => {
    // });
    mb_RNEvent.addObserver('backToFrontKey', 'backToFront', () => {
      tenantState.reqServers2({ chan_code: chan_code.value }).then((res:any) => {
        if (res) {
          if (res.sysAppVersion) {
            if (res.sysAppVersion.version_serial) {
              appUpdateVersion.value = 'V1.0.' + res.sysAppVersion.version_serial;
              if (Number(appVersion.value) !== Number(res.sysAppVersion.version_serial)) {
                newAppVersion.value = res.sysAppVersion.version_serial;
                updateflag.value = true;
              }
            }
            if (res.sysAppVersion.renewal_content) {
              appUpdateContent.value = res.sysAppVersion.renewal_content;
            }
            mandatoryUpate.value = Number(res.sysAppVersion.is_mandatory_upate) || 0;
            if (isIOS()) {
              updateUrl.value = res.sysAppVersion.ios_download_url;
            } else if (isAndroid()) {
              updateUrl.value = res.sysAppVersion.download_url;
            } else {
              updateUrl.value = downUrl.value;
            }
            if (res.sysAppVersion.update_type) {
              updateType.value = res.sysAppVersion.update_type;
            }
          }
        }
      });
    });
    // 更新弹窗逻辑完成
    // 监听app 3方游戏关闭
    // eslint-disable-next-line no-unused-vars
    mb_RNEvent.addObserver(`thirdGameCloseKey`, 'thirdGameClose', e => {
      if (isLogined.value) {
        const thirdGameId = getSession('THIRD_GAME_PLAY_ID') || '';
        setTimeout(async () => {
          let payload:any = {type: 3};
          if (thirdGameId) {
            payload.play_id = thirdGameId
          }
          await getTransitionAllYe(payload);
        }, 1000);
      }
    });
    return {
      origin: window.location.origin,
      tenantInfo,
      showRefshBtn, // 显示刷新按钮
      errInfo,
      pageLoaded, // 显示loaing
      reloadWindows, // 点击刷新按钮
      // 更新弹窗数据
      updateflag,
      appVerName,
      appUpdateVersion,
      appUpdateContent,
      mandatoryUpate,
      changeUpdateFlag,
      updateAppVersion,
      // 更新弹窗数据完成
      // ip限制和维护
      isIpLimited,
      systemRepair,
    };
  }
});
</script>

<style lang="scss" scoped>
.updateModal {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .updateContent {
    width: 80%;
    min-height: 100px;
    background: #fff;
    border-radius: 10px;
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .updateTop {
    .updateTitle {
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
    }
    .uContent {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      margin-bottom: 20px;
    }
  }
  .updateBottom {
    padding: 0 10px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #f3f3f3;
    font-size: 14px;
    .updateBtn {
      white-space: nowrap;
      overflow: hidden;
      span {
        padding: 0 10px;
        text-overflow: ellipsis;
        width: calc(50% - 20px);
        display: inline-block;
        overflow: hidden;
      }
      .red {
        color: var(--red);
      }
    }
    .mandatoryeBtn {
      width: 100%;
      display: flex;
      span {
        padding: 0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 50%;
      }
      span:first-child {
        border-right: 1px solid #f3f3f3;
      }
    }
  }
}
// 弹窗判断
.app-pop-dom {
  height: 100%;
  :deep(.lotteryPageOver) {
    opacity: 1; transition: opacity .3s;
    &:has( ~ .roomBet-view .room-bet-popmain) {
      opacity: 0;
    }
  }
}
</style>
